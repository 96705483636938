<template>
  <modal
    :width="800"
    :adaptive="true"
    class="passportModal CEModal modal-scroll-bar"
    name="passportModal"
  >
    <h2 class="text-center m-b-10">
      {{ $t('TOUR_ORDER.PASSENGER_PASSPORT_DETAILS') }}
    </h2>
    <p class="text-center m-b-30">
      {{ $t('TOURISM_REPORT.PASSPORT_DETAIL_TXT') }}
    </p>
    <div class="form-content">
      <div class="form-group-passport">
        <div class="form-item">
          <label for="">{{ $t('TOUR_ORDER.FITST_NAME_EN') }}</label>
          <input type="text" class="input" v-model="form.firstName" />
        </div>
        <div class="form-item">
          <label for="">{{ $t('TOUR_ORDER.LAST_NAME_EN') }}</label>
          <input type="text" class="input" v-model="form.lastName" />
        </div>
      </div>
      <div class="form-group-passport">
        <div class="form-item">
          <label for="">{{ $t('TOUR_ORDER.NUMBER_OF_PASSPORT') }}</label>
          <input type="text" class="input" v-model="form.passport_number" />
        </div>
        <div class="form-item">
          <label for="">{{ $t('TOUR_ORDER.ID_NUMBER') }}</label>
          <input type="text" class="input" v-model="form.idNumber" />
        </div>
      </div>
      <div class="form-group-passport">
        <div class="form-item">
          <label for="">{{ $t('TOUR_ORDER.PASSPORT_EXPIRATION_DATE') }}</label>
          <input type="text" class="input" v-model="form.validity" />
        </div>
        <div class="form-item">
          <label for="">{{ $t('TOUR_ORDER.DATE_OF_BIRTH') }}</label>
          <input type="text" class="input" v-model="form.dateOfBirth" />
        </div>
      </div>
      <div class="form-group-passport">
        <div class="form-item">
          <label for="">{{ $t('TOUR_ORDER.PASSPORT_BASE_COUNTRY') }}</label>
          <input type="text" class="input" v-model="form.passportCountry" />
        </div>
        <div class="form-item">
          <label for="">{{ $t('TOUR_ORDER.GENDER') }}</label>
          <div class="tour-person__item-wrapper">
            <button
              v-bind:class="[
                form.gender == 'M' ? 'tour-person__sex_active' : '',
                'button tour-person__sex',
              ]"
              @click="selectGender('M')"
              ref="male"
            >
              {{ $t('TOUR_ORDER.MAN') }}
            </button>
            <button
              v-bind:class="[
                form.gender == 'F' ? 'tour-person__sex_active' : '',
                'button tour-person__sex',
              ]"
              @click="selectGender('F')"
              ref="female"
            >
              {{ $t('TOUR_ORDER.WIFE') }}
            </button>
          </div>
        </div>
      </div>
      <button class="primary-btn" @click="closePassportModal">
        {{ $t('GIFTCARD_PRODUCT.APPROVAL') }}
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'PassportModal',
  props: {
    passport: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        passport_number: "",
        idNumber: "",
        validity: "",
        dateOfBirth: "",
        passportCountry: "",
        gender: "M"
      },
    }
  },
  watch: {
    passport: function() {
      this.setModalData();
    }
  },
  methods: {
    setModalData() {
      this.form.firstName = this.passport.firstName;
      this.form.lastName = this.passport.lastName;
      this.form.passport_number = this.passport.idNum;
      this.form.idNumber = this.passport.idNumber;
      this.form.validity = this.passport.validity;
      this.form.dateOfBirth = this.passport.dateOfBirth;
      this.form.passportCountry = this.passport.passportCountry;
      this.form.gender = this.passport.gender;
    },
    closePassportModal() {
      this.$modal.hide("passportModal");
    },
    selectGender(gender) {
      this.form.gender = gender;
    },
  }
};
</script>

<style lang="scss" scoped>
  .passportModal {
    .form-content {
      .form-group-passport {
        max-width: 650px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        .form-item {
          padding: 0 20px;
          width: 50%;
          margin-bottom: 20px;
        }
      }
      .primary-btn {
        margin: 20px auto;
        max-width: 300px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .passportModal {
      .form-content {
        .form-group-passport {
          flex-wrap: wrap;
          .form-item {
            width: 100%;
          }
        }
      }
    }
  }
</style>