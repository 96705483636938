<template>
  <main class="tourism-product page_bg_grey">
    <div
      class="tourism-product__container"
      v-if="Object.keys(reportDetails).length !== 0"
    > 
      <div class="header-summary">
        <h5>{{ $t("TOURISM_REPORT.TRANSACTION_DETAILS") }} {{ reportDetails.transaction.id}}</h5>
        <p>{{ reportDetails.dealOrder.lastName }} {{ reportDetails.dealOrder.phone_number}}</p>
        <p>{{ $t("TOURISM_REPORT.SELLING_RESELLER") }}: {{ reportDetails.business.name }}</p>
        <p>{{ $t("HEADER.CUSTOMER_NUMBER") }}: {{ reportDetails.business.id }}</p>
      </div>
      <div class="tourism-product__wrapper">
        <div class="tourism-product__wrapper-right">
          <section class="destination tourism-product__destination">
            <h1 class="title title__h1 destination__title" v-if="reportDetails.hotelData.HotelID">
              {{gsv(reportDetails.hotelData, ["HotelLocation", "CityName"])}},&nbsp;
              {{ gsv(reportDetails.hotelData, ["HotelName"]) }}
            </h1>
            <h1 class="title title__h1 destination__title" v-else>
              {{ reportDetails.dealOrder.Destination.country }},&nbsp;
              Organized tours for {{ reportDetails.hotelData.tourData.nights }} nights
            </h1>

            <div class="destination__info" v-if="reportDetails.hotelData.HotelID">
              <div class="destination__wrapper d-block">
                <div class="destination__stars">
                  <svg
                    class="destination__star"
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="25"
                    viewBox="0 0 26 25"
                    v-for="index in parseInt(gsv(reportDetails.hotelData, ['HotelRate'], 0))"
                    :key="index"
                  >
                    <defs:style>
                      .star-dark { fill: #002036; }
                    </defs:style>
                    <path
                      class="star-dark"
                      d="M32.158.857,28.97,7.321l-7.133,1.04a1.563,1.563,0,0,0-.864,2.666l5.161,5.029-1.221,7.1A1.561,1.561,0,0,0,27.178,24.8l6.381-3.354L39.941,24.8a1.563,1.563,0,0,0,2.265-1.645l-1.221-7.1,5.16-5.029a1.563,1.563,0,0,0-.864-2.666l-7.133-1.04L34.961.857a1.564,1.564,0,0,0-2.8,0Z"
                      transform="translate(-20.5 0.013)"
                    />
                  </svg>
                </div>
                <p class="text destination__text">
                  {{
                    gsv(reportDetails.hotelData.HotelRemarks, [0, "FreeText"])
                  }}
                </p>
              </div>
            </div>
            <SocilalShare></SocilalShare>
          </section>

          <section class="flight tourism-product__flight">
            <button
              class="button flight__chevron"
              @click="toggleFlight()"
              :class="showFlight ? 'rotate-minus-90' : 'rotate-chevron'"
            >
              <h2 class="title title__h2 flight__title">
                {{ $t("TOURISM_PRODUCT.FLIGHT_DETAILS") }}
              </h2>
            </button>

            <div class="flight__wide-wrapper" v-show="showFlight">
              <div class="flight__wrapper">
                <FlightContainer :flight="reportDetails.dealOrder.DealOrderItems[0].flightsPair"></FlightContainer>
              </div>
            </div>
          </section>

          <section
            class="hotel tourism-product__hotel @@hotel footer-border-del"
            v-if="reportDetails.hotelData.HotelID"
          >
            <button
              class="button hotel__top-btn"
              @click="toggleHotel()"
              :class="showHotel ? 'rotate-minus-90' : 'rotate-chevron'"
            >
              <h2 class="title title__h2 hotel__title">
                {{ reportDetails.hotelData.HotelName }}
              </h2>
              <div class="hotel__stars">
                <svg
                  class="hotel__star"
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="25"
                  viewBox="0 0 26 25"
                  v-for="index in parseInt(gsv(reportDetails.hotelData, ['HotelRate'], 0))"
                  :key="index"
                >
                  <defs:style>
                    .star-dark { fill: #002036; }
                  </defs:style>
                  <path
                    class="star-dark"
                    d="M32.158.857,28.97,7.321l-7.133,1.04a1.563,1.563,0,0,0-.864,2.666l5.161,5.029-1.221,7.1A1.561,1.561,0,0,0,27.178,24.8l6.381-3.354L39.941,24.8a1.563,1.563,0,0,0,2.265-1.645l-1.221-7.1,5.16-5.029a1.563,1.563,0,0,0-.864-2.666l-7.133-1.04L34.961.857a1.564,1.564,0,0,0-2.8,0Z"
                    transform="translate(-20.5 0.013)"
                  />
                </svg>
              </div>
            </button>

            <div class="hotel__wide-cover" v-show="showHotel">
              <div class="hotel__cover">
                <div class="hotel__container">
                  <p class="text hotel__text">
                    {{
                      gsv(reportDetails.hotelData.HotelRemarks, [0, "FreeText"])
                    }}
                  </p>

                  <div class="hotel__facilities">
                    <h3 class="title title__h3 hotel__title-h3">
                      {{ $t("TOURISM_PRODUCT.FACILITIES") }}
                    </h3>
                    <div class="hotel__wrapper">
                      <div
                        v-for="(facility, index) in reportDetails.hotelData
                          .HotelFacilities"
                        :key="index"
                        class="facility"
                      >
                        <img :src="facility.Url" alt />
                        <span>{{
                          $t("FACILITY." + facility.FacilityCode.toUpperCase())
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section class="trip-details tourism-product__trip-details">
          <div class="trip-details__cover">
            <div class="trip-details__header">
              <div class="logo trip-details__logo">
                <a class="link" href="index.html">
                  <img class="img" :src="currentLogo" alt />
                </a>
              </div>
              <button class="button trip-details__close">
                <img src="/assets/img/close.svg" alt />
              </button>
            </div>

            <div class="trip-details__container">
              <div class="trip-details__caption">
                <span class="trip-details__span"
                  >{{ $t("TOURISM_PRODUCT.OUT_OF_COMMISSION") }}:</span
                >
                <h2 class="title title__h2 trip-details__title">
                  <span class="trip-details__price">{{
                    reportDetails.dealOrder.final_price
                  }}</span>
                  <span class="trip-details__current">
                    {{ $t("COMMON.NIS") }}</span
                  >
                </h2>
              </div>

              <div class="trip-details__commission">
                <div class="trip-details__commission-wrapper">
                  <p class="text trip-details__text">
                    {{ $t("TOURISM_PRODUCT.OUT_OF_COMMISSION") }}:
                  </p>
                  <span
                    class="price-person"
                  >
                    <b class="trip-details__commission-value m-0">{{
                      reportDetails.transaction.profit
                    }} </b>
                    <b class="trip-details__commission-current">{{
                      $t("COMMON.NIS")
                    }}</b>
                  </span>
                </div>
              </div>
              <div class="trip-details__form">
                <div class="trip-details__dates">
                  <label class="trip-details__label" for>{{
                    $t("COMMON.DATES")
                  }}</label>
                  <input
                    class="input trip-details__input"
                    type="text"
                    :value="
                      reportDetails.dealOrder.departureDate +
                      '-' +
                      reportDetails.dealOrder.returnDate.slice(-2)
                    "
                    readonly
                  />
                </div>
                <div
                  class="room-detail"
                  v-for="(room, index) in reportDetails.dealOrder
                    .DealOrderItems"
                  :key="index"
                >
                  <div class="trip-details__passangers">
                    <label class="trip-details__label" for>{{
                      $t("TOURISM.PASSENGERS")
                    }}</label>
                    <input
                      class="input trip-details__input"
                      type="text"
                      :value="getRoomStringByPax(room.paxComplect)"
                      readonly
                    />
                  </div>

                  <div class="trip-details__people">
                    <!-- <div class="trip-details__labels">
                      <span class="trip-details__labels-item">{{
                        $t("TOURISM_PRODUCT.ROOMS")
                      }}</span>
                      <span class="trip-details__labels-item">{{
                        $t("TOURISM.ADULTS")
                      }}</span>
                      <span class="trip-details__labels-item"
                        >2-12 {{ $t("TOURISM.CHILDREN") }}</span
                      >
                      <span class="trip-details__labels-item"
                        >0-2 {{ $t("TOURISM_PRODUCT.BABIES") }}</span
                      >
                    </div> -->
                    <div class="trip-details__passangers room-part">
                      <div class="faBasis">
                        <label class="trip-details__label" for>{{
                          $t("TOURISM_PRODUCT.ACCOMMODATION_LEVEL")
                        }}</label>
                        <input class="input" :value="room.fareBasis" readonly />
                      </div>
                      <div class="roomType">
                        <label class="trip-details__label" for>{{
                          $t("TOURISM_PRODUCT.ROOM_TYPE")
                        }}</label>
                        <input class="input fs16" :value="room.roomType" readonly />
                      </div>
                    </div>
                  </div>

                  <div class="passport-block bg-white" v-if="room.persons.length">
                    <h5>
                      {{ $t("TOURISM.PASSENGERS") }}
                    </h5>
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="one-passport" v-for="(passport, i) in room.persons" :key="i">
                        <div class="passport-data p-relative">
                          <button class="button tour-passport__added-pencil" @click="openPassportModal(passport)">
                            <svg
                              class="tour-passport__svg-small"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="#000"
                            >
                              <path
                                d="M19.268,2.2,17.8.733a2.5,2.5,0,0,0-3.536,0L.5,14.5l-.5,4.46a.938.938,0,0,0,1.035,1.035l4.46-.5L19.268,5.733a2.5,2.5,0,0,0,0-3.536ZM15.55,7.684,6.8,16.436V15.079H4.922V13.2H3.565l8.752-8.752L15.55,7.684ZM4.928,18.305l-2.3.256L1.44,17.378l.256-2.3.776-.776H3.829v1.875H5.7V17.53ZM18.384,4.849,16.434,6.8,13.2,3.568l1.951-1.951a1.249,1.249,0,0,1,1.768,0l1.464,1.464A1.249,1.249,0,0,1,18.384,4.849Z"
                                transform="translate(-0.001 -0.001)"
                              />
                            </svg>
                          </button>
                          <button class="button tour-passport__added-trash">
                            <svg
                              class="tour-passport__svg-small"
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="20"
                              viewBox="0 0 18 20"
                              fill="#000"
                            >
                              <path
                                d="M17.188,2.5H13.125L11.812.75a1.875,1.875,0,0,0-1.5-.75H7.188a1.875,1.875,0,0,0-1.5.75L4.375,2.5H.313A.313.313,0,0,0,0,2.813v.625a.312.312,0,0,0,.313.312h.738l1.3,14.543A1.875,1.875,0,0,0,4.215,20h9.07a1.875,1.875,0,0,0,1.867-1.707l1.3-14.543h.738a.313.313,0,0,0,.313-.312V2.813A.313.313,0,0,0,17.188,2.5Zm-10.5-1a.629.629,0,0,1,.5-.25h3.125a.629.629,0,0,1,.5.25l.75,1H5.937Zm7.219,16.68a.621.621,0,0,1-.621.57H4.215a.621.621,0,0,1-.621-.57L2.3,3.75H15.2Z"
                              />
                            </svg>
                          </button>
                          <p>{{ $t("COMMON.NAME") }}: {{ passport.firstName + passport.lastName }}</p>
                          <p>{{ $t("COMMON.BIRTHDAY") }}: {{ passport.dateOfBirth }}</p>
                          <p>{{ $t("TOUR_ORDER.NUMBER_OF_PASSPORT") }}: {{ passport.idNumber }}</p>
                          <p>{{ $t("TOUR_ORDER.VALIDITY") }}: {{ passport.validity }}</p>
                        </div>
                        <p class="passport-index">
                          {{ $t("COMMON.NAME") }} {{i + 1}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <button class="primary-btn return-btn" @click="closeWindow()">
        {{ $t("COMMON.RETURN")}}
      </button>
    </div>
    <div v-else>
      <h2
        class="title title__h2 tourism__title text-center m-t-30"
        v-if="!loading"
      >
        No data
      </h2>
    </div>
    <PassportModal :passport="passportItemData"></PassportModal>
  </main>
</template>

<script>
import SocilalShare from "./SocilalShare.vue"
import FlightContainer from "./FlightContainer.vue"
import PassportModal from "./PassportModal.vue"
export default {
  name: "TourismReport",
  components: {
    SocilalShare,
    FlightContainer,
    PassportModal
  },
  props: {
    reportDetails: {
      type: [Object, Array],
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      showHotel: true,
      showFlight: true,
      passportItemData: {}
    };
  },
  methods: {
    /* toggle collapse part */
    toggleHotel() {
      this.showHotel = !this.showHotel;
    },
    toggleFlight() {
      this.showFlight = !this.showFlight;
    },

    /* get selected passengers string */
    getRoomStringByPax(pax) {
      const adult_num = (pax.match(new RegExp("A", "g")) || []).length;
      const child_num = (pax.match(new RegExp("C", "g")) || []).length;
      const infant_num = (pax.match(new RegExp("I", "g")) || []).length;
      const result =
        adult_num +
        " " +
        this.$t("TOURISM.ADULTS") +
        ", " +
        child_num +
        " " +
        this.$t("TOURISM.CHILDREN") +
        ", " +
        infant_num +
        " " +
        this.$t("TOURISM_PRODUCT.BABIES");
      return result;
    },
    closeWindow() {
      window.open('','_parent','');
      window.top.close();
    },
    /* passport detail modal */
    openPassportModal(passport) {
      this.passportItemData = passport;
      this.$modal.show("passportModal");
    },
  }
}
</script>

<style lang="scss">
.rotate-chevron::before {
  transform: rotate(90deg);
}
.rotate-minus-90::before {
  transform: rotate(-90deg);
}
ul#vs2__listbox.vs__dropdown-menu {
  width: 50px !important;
  max-width: 50px !important;
}
.tourism-product__ticket,
.tourism-product__trip-details {
  width: calc(40% - 60px);
  flex-basis: unset;
}

.footer-border-del:before {
  content: none;
}

.hotel__stars {
  justify-content: flex-start;
  width: auto;
  .hotel__star {
    width: 25px;
  }
}
.hotel__wrapper {
  width: unset;
  height: unset;
  .facility {
    width: 33%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    img {
      margin: 0 10px;
    }
    span {
      font-size: 16px;
    }
  }
}
// ltr & rtl design bug fix
.destination__stars {
  .destination__star {
    width: 20px;
  }
}
.hotel__title {
  margin-right: 0;
}
.tourism-product__add-btn {
  margin-right: 0;
}
.card__share {
  margin-right: 0;
}
.trip-details__title {
  margin-left: 0;
}
.trip-details__discount {
  margin-left: 0;
  &::before {
    left: 0;
  }
  &::after {
    left: 0;
  }
}
.trip-details__room {
  padding-left: 0;
}

.ltr-type {
  .destination__stars {
    margin-left: 0;
  }
  .hotel__title {
    margin-left: 0;
    margin-right: 10px;
  }
  .tourism-product__add-btn {
    margin-right: 10px;
    margin-left: 0;
  }
  .card__share {
    margin-right: 25px;
    margin-left: 0;
  }
  .trip-details__title {
    margin-left: 10px;
    margin-right: 0;
  }
  .trip-details__discount {
    margin-left: 55px;
    margin-right: 0;
    &::before {
      left: -30px;
      right: 0;
    }
    &::after {
      left: -27px;
      right: 0;
    }
  }
  .trip-details__room {
    padding-right: 0;
    padding-left: 12px;
  }
}
.passportModal {
  .vm--modal {
    padding: 30px 10px;
  }
}
</style>

<style lang="scss" scoped>
.category__current {
  font-size: 18px;
}
.number-input {
  width: 50px;
  text-align: center;
  padding: 0;
}
.room-part {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1495px) {
    flex-direction: column;
  }
  .faBasis {
    width: 100%;
    margin-left: 30px;
    @media (max-width: 1495px) {
      margin-bottom: 30px;
    }
  }
  .roomType {
    width: 100%;
  }
}
.price-person {
  margin-right: 10px;
  white-space: nowrap;
}
.room-detail {
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #b2bbc2;
    position: absolute;
    top: -14px;
  }
}
.ltr-type {
  .faBasis {
    width: 100%;
    margin-right: 30px;
    margin-left: 0px;
  }
  .price-person {
    margin-right: 0;
    margin-left: 10px;
  }
}

.passport-block {
  width: 100%;
  padding: 10px 20px;
  margin-top: 30px;
  h5 {
    text-align: center;
    font-weight: 700px;
    font-size: 18px;
  }
  .one-passport {
    width: 45%;
    margin-top: 20px;
    .passport-data {
      background-color: #B2BBC2;
      padding: 50px 10px 10px;
      p {
        font-size: 16px;
        font-weight:  700;
      }
    } 
    .passport-index {
      text-align: center;
      font-size: 16px;
    }
  }
}
.header-summary {
  text-align: center;
  font-size: 20px;
  margin: 0 auto;
  h5 {
    font-size: 20px;
  }
  p {
    margin-bottom: 0;
  }
}
.return-btn {
  margin: 0 auto;
  margin-top: 50px;
  background-color: #fcb340;
}

</style>